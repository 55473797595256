import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';


const firebaseConfig = {
    apiKey: "AIzaSyCJA1XHGpcBh0iFx7DDVtgOrI4aZURlJUc",
    authDomain: "expanded-symbol-403415.firebaseapp.com",
    projectId: "expanded-symbol-403415",
    storageBucket: "expanded-symbol-403415.appspot.com",
    messagingSenderId: "553862526302",
    appId: "1:553862526302:web:a283ca80c1ee7d93850bcf"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export {
    auth,
    app
}