import { Restaurant } from '@tout-ma/general_models';
import React, { useContext, useEffect, useState } from 'react';
import { getListRestaurants, getRestaurantLoginToken } from '../../api/restaurants';
import { t } from 'i18next';
import { Form, Button, Card, Col, Input, Row, Table, TableColumnsType, Badge, Tag, Modal, Space } from 'antd';
import { SearchOutlined, RedoOutlined, EditFilled, PlusCircleOutlined, LoginOutlined, CopyOutlined } from '@ant-design/icons';
import { NotificationsContext } from '../../contexts/NotificationsContext';
import { useNavigate } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';

export type restaurantSearchForm = {
    name: string;
};
const AllRestaurantsScreen = () => {
    const [form] = Form.useForm<restaurantSearchForm>();
    const [Restaurants, setRestaurants] = useState<Restaurant[]>();
    const [Total, setTotal] = useState<number>(0);
    const { showNotification } = useContext(NotificationsContext);
    const navigate = useNavigate();

    useEffect(() => {
        onFinish({ name: '' });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const getLoginToken = async (id: number) => {
        try {
            let token = await getRestaurantLoginToken(id);
            Modal.warning({
                title: 'Token is only valid for 1 hour',
                content: (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                            width: '300px',
                            height: '100px',
                        }}
                    >
                        <b style={{ fontSize: 40, marginBottom: '10px' }}>{token}</b>
                        <div>
                            <CopyToClipboard text={`${token}`}>
                                <Button icon={<CopyOutlined />}>{'Copy'}</Button>
                            </CopyToClipboard>
                        </div>
                    </div>
                ),
            });
        } catch (error: any) {
            showNotification?.('error', 'Error', error.message);
        }
    };

    const onFinish = async (values: restaurantSearchForm) => {
        try {
            const restaurants = await getListRestaurants(values);
            setRestaurants(restaurants.list);
            setTotal(restaurants.total);
        } catch (error: any) {
            showNotification?.('error', 'Error', error.message);
        }
    };
    const menuColumns: TableColumnsType<Restaurant> = [
        {
            title: 'Online',
            key: 'online',
            render: (restaurant: Restaurant) => <Tag color={'red'}>{'Offline'}</Tag>,
        },
        { title: 'Name', key: 'name', dataIndex: 'name' },
        {
            title: 'Activated',
            key: 'activated',
            render: (restaurant: Restaurant) => <Badge status={restaurant.active ? 'success' : 'error'} />,
        },
        {
            title: '',
            key: 'operation',
            render: (restaurant: Restaurant) => (
                <span>
                    <Button icon={<EditFilled />} size="small" onClick={() => navigate(`edit/${restaurant.id}`)} />
                    <Button icon={<LoginOutlined />} onClick={() => getLoginToken(restaurant.id)} style={{ marginLeft: '10px' }} size="small"></Button>
                </span>
            ),
        },
    ];
    return (
        <Card title={t('Restaurants')} style={{ margin: 20, height: '100%' }}>
            <Card style={{ marginTop: '10px', marginBottom: '10px' }}>
                <Form layout="vertical" form={form} name="userLookup" onFinish={onFinish}>
                    <Row style={{ alignItems: 'center', marginTop: '10px' }}>
                        <Col span={10} offset={1}>
                            <Form.Item name={'name'} label="Restaurant name" rules={[{ required: false }]}>
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={10} offset={2}>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                                <Button htmlType="button" style={{ width: '45%' }} block icon={<RedoOutlined />} onClick={() => form.resetFields()}>
                                    Reset form
                                </Button>
                                <Button type="default" style={{ width: '45%' }} block icon={<SearchOutlined />} htmlType="submit">
                                    Look Up
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
                <Row>
                    <Col span={20} offset={2}>
                        <Button size="small" block icon={<PlusCircleOutlined />} onClick={() => navigate('edit/0')}>
                            Add new restaurant
                        </Button>
                    </Col>
                </Row>
            </Card>
            <Table columns={menuColumns} dataSource={Restaurants} size="small" pagination={false} />
            <b>Total Results: {Total}</b>
        </Card>
    );
};

export default AllRestaurantsScreen;
