import React, { useState, createContext, useContext } from 'react';
import { auth } from '../constants/firebase';
import { browserLocalPersistence, User as fbUser, onAuthStateChanged, setPersistence, signInWithCustomToken, signInWithEmailAndPassword } from 'firebase/auth';
import { Api } from '../api';
import { t } from 'i18next';
import { differenceInMilliseconds } from 'date-fns';
import LoginScreen from '../screens/auth/LoginScreen';
import { quickcheckAccess } from '../api/users';
import { NotificationsContext } from './NotificationsContext';

type signInWithEmailformProps = {
    email: string;
    password: string;
};

type AuthContextType = {
    User?: fbUser;
    signInWithEmail?: (values: signInWithEmailformProps) => Promise<void>;
    logout?: () => void;
};

let refreshingAuth = false;

export const AuthContext = createContext<AuthContextType>({});

export const AuthContextProvider = ({ children }: { children: React.ReactNode | React.ReactNode[] }) => {
    //This is a fb user and not a db user
    const [User, setUser] = useState<fbUser>();
    const [expiry, setExpiry] = useState<Date | null>(null);
    const { showNotification } = useContext(NotificationsContext);

    async function updateToken(token: string | null, expiry: Date | null) {
        if (token) Api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        else delete Api.defaults.headers.common['Authorization'];
        setExpiry(expiry);
        //        localStorage.setItem('token', token ?? '');
    }

    const logout = React.useCallback(() => {
        console.log('logout');
        auth.signOut();
        setUser(undefined);
        updateToken(null, null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const signInWithEmail = async ({ email, password }: signInWithEmailformProps) => {
        setPersistence(auth, browserLocalPersistence)
            .then(() => {
                // Existing and future Auth states are now persisted in the current
                // session only. Closing the window would clear any existing state even
                // if a user forgets to sign out.
                // ...
                // New sign-in will be persisted with session persistence.
                return signInWithEmailAndPassword(auth, email, password).catch((error) => {
                    console.log('hitt', error?.code);
                    if (error?.code === 'auth/user-disabled') {
                        showNotification?.('error', t('Oops, something went wrong'), t('Account has been disabled by admin'));
                    } else if (error?.code === 'auth/invalid-credential') {
                        showNotification?.('error',t('Oops, something went wrong'), t('You entered invalid credentials'));
                    } else {
                        showNotification?.('error',t('Oops, something went wrong'), t('Please try again or contact support'));
                    }
                    logout();
                });
            })
            .catch((error) => {
                console.error('AuthContext signInWithEmailAndPassword error', error);
            });
    };

    //Firebase auth state change
    React.useEffect(() => {
        // Firebase auth state change
        const firebaseAuthStateChangeListener = onAuthStateChanged(
            auth,
            async (firebaseUser) => {
                try {
                    if (firebaseUser) {
                        const { token, expirationTime } = await firebaseUser.getIdTokenResult();
                        await quickcheckAccess(token);
                        if (!User) setUser(firebaseUser);
                        updateToken(token, new Date(expirationTime));
                    } else if (User) {
                        setUser(undefined);
                        updateToken(null, null);
                    }
                } catch (e: any) {
                    showNotification?.('error',t('Oops, something went wrong'));
                    logout();
                }
            },
            (error) => {
                console.error('AuthContext onAuthStateChanged error', error);
                if (User) logout();
            }
        );
        const requestInterceptorId = Api.interceptors.request.use(async (request) => {
            const currentUser = auth.currentUser;
            if (currentUser) {
                try {
                    const currentTokenResult = await currentUser.getIdTokenResult();

                    const diff = differenceInMilliseconds(new Date(currentTokenResult.expirationTime), new Date());
                    // Refresh every 5 minutes
                    if (diff < 3300000 && !refreshingAuth) {
                        refreshingAuth = true;
                        const tokenResult = await auth.currentUser?.getIdTokenResult(true);
                        if (!tokenResult?.token) throw new Error();

                        updateToken(tokenResult.token, new Date(tokenResult.expirationTime));
                    }
                } catch (e: any) {
                    logout();
                } finally {
                    refreshingAuth = false;
                }
            }

            return request;
        });

        return () => {
            firebaseAuthStateChangeListener();
            Api.interceptors.request.eject(requestInterceptorId);
        };
    }, [logout]);

    //token refresh
    React.useEffect(() => {
        const refreshToken = async () => {
            const result = await auth.currentUser?.getIdTokenResult(true); // force refresh token
            if (result) {
                updateToken(result.token, new Date(result.expirationTime));
            }
        };

        const checkExpiry = () => {
            if (expiry && differenceInMilliseconds(expiry, new Date()) < 300000) {
                // 5 mins
                refreshToken();
            }
        };

        checkExpiry();
    }, [expiry]);

    if (!User) return <LoginScreen signInWithEmail={signInWithEmail} />;

    return <AuthContext.Provider value={{ User, logout, signInWithEmail }}>{children}</AuthContext.Provider>;
};
