import { useParams } from 'react-router-dom';
import React, { useContext, useEffect, useState } from 'react';
import { addNewRestaurant, getFullRestaurant, getListRestaurants, updateRestaurant } from '../../api/restaurants';
import { t } from 'i18next';
import {
    Form,
    Button,
    Card,
    Col,
    Input,
    Row,
    Table,
    TableColumnsType,
    Badge,
    Tag,
    Select,
    SelectProps,
    Transfer,
    TransferProps,
    Collapse,
    Upload,
    UploadFile,
    InputNumber,
    Image,
} from 'antd';
import { SearchOutlined, RedoOutlined, ArrowLeftOutlined, PlusCircleOutlined, GoogleOutlined, UploadOutlined } from '@ant-design/icons';
import { NotificationsContext } from '../../contexts/NotificationsContext';
import { useNavigate } from 'react-router-dom';
import { DefaultLocales, RestaurantCategories, User } from '@tout-ma/general_models';
import { getListUsers } from '../../api/users';
import { AdvancedMarker, Map, useMap, useMapsLibrary } from '@vis.gl/react-google-maps';
import { addressToLatLng } from '../../api/googleMaps';
import HomeMarker from '../../components/maps/HomeMarker';
import { translateText } from '../../api/googleTranslate';
export type editRestaurantForm = {
    originalLang: DefaultLocales;
    file: any;
    name: string;
    address: {
        streetName?: string;
        houseNumber?: string;
        houseNumberAddition?: string;
        postalCode?: string;
        cityName?: string;
        provinceName?: string;
        district?: string;
    };
    lat: number;
    lng: number;
    categories: RestaurantCategories[];
    restaurantOwnerId: number;
    description: {
        en?: string;
        fr?: string;
        es?: string;
        ar?: string;
        nl?: string;
        description: string;
    };
    minDeliveryOrderAmount: number;
};
const EditRestaurant = () => {
    let { id } = useParams();
    const [Loaded, setLoaded] = useState(false);
    const [selectedLang, setselectedLang] = useState<DefaultLocales>();
    const languages: DefaultLocales[] = [DefaultLocales.EN, DefaultLocales.FR, DefaultLocales.ES, DefaultLocales.AR, DefaultLocales.NL];
    const [form] = Form.useForm<editRestaurantForm>();
    const [LatLngRestaurant, setLatLngRestaurant] = useState<{ lat: number; lng: number }>();
    const [CurrentRestaurantImage, setCurrentRestaurantImage] = useState<string>();
    useEffect(() => {
        if (id && id !== '0' && !Loaded) {
            (async () => {
                setLoaded(true);

                let restaurant = await getFullRestaurant(parseInt(id));
                form.setFieldValue('originalLang', restaurant?.description?.originalLang??undefined);
                form.setFieldValue('name', restaurant.name);
                form.setFieldValue('address', restaurant.address);
                setLatLngRestaurant({ lat: restaurant.lat, lng: restaurant.lng });
                form.setFieldValue('categories', restaurant.categories);
                form.setFieldValue(['description', DefaultLocales.EN], restaurant?.description?.en ?? undefined);
                form.setFieldValue(['description', DefaultLocales.FR], restaurant?.description?.fr ?? undefined);
                form.setFieldValue(['description', DefaultLocales.ES], restaurant?.description?.es ?? undefined);
                form.setFieldValue(['description', DefaultLocales.AR], restaurant?.description?.ar ?? undefined);
                form.setFieldValue(['description', DefaultLocales.NL], restaurant?.description?.nl ?? undefined);
                form.setFieldValue(['description', 'description'], restaurant?.description?.[restaurant?.description?.originalLang as DefaultLocales]??undefined);
                form.setFieldValue('minDeliveryOrderAmount', restaurant.minDeliveryOrderAmount);
                if (restaurant.image) setCurrentRestaurantImage(restaurant.image);
                setSelectedCategories(restaurant.categories);

                console.log(restaurant);
            })();
        }
    }, []);

    const navigate = useNavigate();
    const { showNotification } = useContext(NotificationsContext);
    const [OwnerResults, setOwnerResults] = useState<SelectProps['options']>([]);
    const [SelectedCategories, setSelectedCategories] = useState<string[]>([]);
    const handleChange: TransferProps['onChange'] = (newTargetKeys, direction, moveKeys) => {
        setSelectedCategories(newTargetKeys as any);
    };
    const map = useMap('drawingMap');
    const drawingLibrary = useMapsLibrary('drawing');
    const [file, setFile] = React.useState<UploadFile | null>(null);

    const onTranslate = async (type: 'description') => {
        let currForm = form.getFieldsValue();
        if (!selectedLang) return;

        let languagesToTranslate = languages.filter((x) => x !== selectedLang);

        switch (type) {
            case 'description':
                for (let langLooper = 0; langLooper < languagesToTranslate.length; langLooper++) {
                    const currLang = languagesToTranslate[langLooper];
                    const description = currForm.description?.description;

                    if (description) {
                        let translatedQuickInfo = await translateText(description, selectedLang, currLang);
                        form.setFieldsValue({ description: { [currLang]: translatedQuickInfo } });
                    }
                }
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        if (drawingLibrary && map && LatLngRestaurant) {
            const drawer = new drawingLibrary.DrawingManager({
                drawingMode: null,
                drawingControl: true,
                drawingControlOptions: {
                    position: google.maps.ControlPosition.TOP_CENTER,
                    drawingModes: [google.maps.drawing.OverlayType.MARKER],
                },
            });
            drawer.setMap(map);
            google.maps.event.addListener(drawer, 'markercomplete', (circle: google.maps.Marker) => {
                setLatLngRestaurant({ lat: circle.getPosition()?.lat() ?? 0, lng: circle.getPosition()?.lng() ?? 0 });
                circle?.setMap(null);
                drawer.setDrawingMode(null);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [drawingLibrary, map]);

    const onFinish = async (values: editRestaurantForm) => {
        try {
            const formData = new FormData();

            formData.append('originalLang', values.originalLang);
            setselectedLang(values.originalLang);
            if (file) {
                formData.append('file', values.file?.file as any);
            }
            formData.append('name', values.name);
            formData.append('address', JSON.stringify(values.address));
            formData.append('lat', (LatLngRestaurant?.lat ?? 0).toString());
            formData.append('lng', (LatLngRestaurant?.lng ?? 0).toString());
            formData.append('categories', JSON.stringify(values.categories));
            formData.append('description', JSON.stringify(values.description));
            formData.append('minDeliveryOrderAmount', values.minDeliveryOrderAmount.toString());
            if (id && id !== '0') {
                formData.append('id', id);
                await updateRestaurant(id, formData);
                showNotification?.('success', 'Success', 'Restaurant updated');
            } else {
                formData.append('restaurantOwnerId', values.restaurantOwnerId.toString());
                await addNewRestaurant(formData);
                showNotification?.('success', 'Success', 'Restaurant created');
            }
            navigate(-1);
        } catch (error: any) {
            showNotification?.('error', 'Error', error.message);
        }
    };
    const handleSearch = async (newValue: string) => {
        let results = await getListUsers({ email: newValue });
        setOwnerResults(results.list.map((user: User) => ({ value: user.id, label: user.email })));
    };

    const geocodeAddress = async () => {
        const address = form.getFieldValue(['address']);
        if (!address || !address?.streetName || !address?.cityName) {
            return;
        }
        const addressToGeocode = `${address.streetName} ${address?.houseNumber} ${address?.houseNumberAddition}, ${address?.postalCode} ${address?.cityName}, ${address?.provinceName}`;
        const response = await addressToLatLng(addressToGeocode);
        if (response?.lat && response?.lng) {
            setLatLngRestaurant(response);
        }
    };

    return (
        <Card
            title={
                <span>
                    <Button icon={<ArrowLeftOutlined />} style={{ marginRight: 10 }} title={t('Go Back')} onClick={() => navigate(-1)} />{' '}
                    {id === '0' ? 'New restaurant' : 'Edit restaurant'}
                </span>
            }
            style={{ margin: 20, height: '100%', overflow: 'scroll' }}
        >
            <Form layout="vertical" form={form} name="userLookup" onFinish={onFinish}>
                {/* Language and picture */}
                <Row style={{ alignItems: 'center' }}>
                    <Col span={10} offset={1}>
                        <Form.Item name="originalLang" labelAlign="left" label="Input language" rules={[{ required: true }]}>
                            <Select onChange={setselectedLang}>
                                <Select.Option value={DefaultLocales.EN}>{t('English')}</Select.Option>
                                <Select.Option value={DefaultLocales.FR}>{t('French')}</Select.Option>
                                <Select.Option value={DefaultLocales.ES}>{t('Spanish')}</Select.Option>
                                <Select.Option value={DefaultLocales.AR}>{t('Arabic')}</Select.Option>
                                <Select.Option value={DefaultLocales.NL}>{t('Dutch')}</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={4} offset={2}>
                        <Form.Item name="file" labelAlign="left" label="Picture">
                            <Upload
                                onRemove={() => setFile(null)}
                                beforeUpload={(file) => {
                                    setFile(file);
                                    return false;
                                }}
                                fileList={file ? [file] : []}
                            >
                                <Button icon={<UploadOutlined />}>Select File</Button>
                            </Upload>
                                <a href='https://redketchup.io/image-resizer' rel="noreferrer" target='_blank'>  8 /22 aspect ratio please</a>
                        </Form.Item>
                    </Col>
                    <Col span={4} offset={1}>
                        {CurrentRestaurantImage && <Image width={200} src={`https://storage.googleapis.com/tout_restaurant_images/${CurrentRestaurantImage}`} />}
                    </Col>
                </Row>

                {/* Restaurant name and owner */}
                <Row style={{ alignItems: 'center', marginTop: '10px' }}>
                    <Col span={10} offset={1}>
                        <Form.Item name={'name'} label="Restaurant name" rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={10} offset={2}>
                        <Form.Item name={'restaurantOwnerId'} label="Restaurant owner" rules={[{ required: !id || id === '0' }]}>
                            <Select
                                disabled={id !== '0'}
                                showSearch
                                placeholder={'Enter the owner email'}
                                defaultActiveFirstOption={false}
                                suffixIcon={null}
                                filterOption={false}
                                onSearch={handleSearch}
                                notFoundContent={null}
                                options={OwnerResults}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                {/* Description and price */}
                <Row style={{ alignItems: 'center', marginTop: '10px' }}>
                    <Col span={10} offset={1}>
                        <Form.Item name={['description', 'description']} label="description" rules={[{ required: false }]}>
                            <Input.TextArea />
                        </Form.Item>
                        <Collapse
                            size="small"
                            style={{ width: '100%', marginBottom: '15px' }}
                            items={[
                                {
                                    key: '1',
                                    label: 'Translated description',
                                    children: (
                                        <React.Fragment>
                                            {languages
                                                .filter((x) => x !== selectedLang)
                                                .map((lang) => (
                                                    <Form.Item name={['description', lang]} label={`Description in '${lang}'`} rules={[{ required: false }]}>
                                                        <Input.TextArea />
                                                    </Form.Item>
                                                ))}
                                            <Row>
                                                <Col span={7} offset={4}>
                                                    <Button
                                                        type="primary"
                                                        disabled={!selectedLang}
                                                        block
                                                        htmlType="button"
                                                        icon={<GoogleOutlined />}
                                                        onClick={() => onTranslate('description')}
                                                    >
                                                        Translate text
                                                    </Button>
                                                </Col>
                                                <Col span={7} offset={1}>
                                                    <Button htmlType="button" block icon={<RedoOutlined />} onClick={() => form.resetFields(['description'])}>
                                                        Reset form
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </React.Fragment>
                                    ),
                                },
                            ]}
                        />
                    </Col>
                    <Col span={10} offset={2}>
                        <Form.Item name="minDeliveryOrderAmount" label="Min. Delivery Amount" rules={[{ required: true, min: 0, type: 'number' }]}>
                            <InputNumber addonAfter="MAD" />
                        </Form.Item>
                    </Col>
                </Row>

                {/* Categories */}
                <Row style={{ alignItems: 'center', marginTop: '10px' }}>
                    <Col span={24} style={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
                        <Form.Item
                            name={'categories'}
                            label="Restaurant Categories"
                            required={true}
                            rules={[
                                {
                                    validator: async (_, categories) => {
                                        console.log(categories);
                                        if (!categories || categories.length < 1) {
                                            return Promise.reject(new Error('At least 1 category is required'));
                                        }
                                    },
                                },
                            ]}
                        >
                            <Transfer
                                dataSource={Object.keys(RestaurantCategories).map((x) => ({ key: x, title: x }))}
                                titles={['Available', 'Selected']}
                                listStyle={{
                                    width: 400,
                                    height: 300,
                                }}
                                targetKeys={SelectedCategories}
                                onChange={handleChange}
                                render={(item) => item.title}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                {/* Address */}
                <Card>
                    <Row>
                        <Col span={10} offset={1}>
                            <Form.Item name={['address', 'streetName']} label="Street name" rules={[{ required: true }]}>
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={4} offset={2}>
                            <Form.Item name={['address', 'houseNumber']} label="House number" rules={[{ required: false }]}>
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={4} offset={2}>
                            <Form.Item name={['address', 'houseNumberAddition']} label="House Number Addition" rules={[{ required: false }]}>
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={4} offset={1}>
                            <Form.Item name={['address', 'postalCode']} label="Postal Code" rules={[{ required: true }]}>
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={4} offset={2}>
                            <Form.Item name={['address', 'cityName']} label="City Name" rules={[{ required: true }]}>
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={4} offset={2}>
                            <Form.Item name={['address', 'provinceName']} label="Province name" rules={[{ required: true }]}>
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={4} offset={2}>
                            <Form.Item name={['address', 'district']} label="District name" rules={[{ required: false }]}>
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={2} offset={11}>
                            <Button block icon={<SearchOutlined />} onClick={geocodeAddress}>
                                lookup
                            </Button>
                        </Col>
                    </Row>
                </Card>

                {/* Map */}
                <Row>
                    {!!LatLngRestaurant && (
                        <Col span={24} style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', height: '400px' }}>
                            <Map
                                defaultCenter={{ lat: LatLngRestaurant?.lat ?? 0, lng: LatLngRestaurant?.lng ?? 0 }}
                                id="drawingMap"
                                mapId={'drawingMap'}
                                defaultZoom={12}
                                gestureHandling={'greedy'}
                                disableDefaultUI={true}
                            >
                                {LatLngRestaurant && (
                                    <AdvancedMarker position={{ lat: LatLngRestaurant?.lat ?? 0, lng: LatLngRestaurant?.lng ?? 0 }}>
                                        <HomeMarker />
                                    </AdvancedMarker>
                                )}
                            </Map>
                        </Col>
                    )}
                </Row>

                {/* Buttons */}
                <Row style={{ alignItems: 'center', marginTop: '10px' }}>
                    <Col span={5} offset={6}>
                        <Button
                            htmlType="button"
                            danger
                            block
                            icon={<RedoOutlined />}
                            onClick={() => {
                                form.resetFields();
                                setLatLngRestaurant(undefined);
                            }}
                        >
                            Reset form
                        </Button>
                    </Col>
                    <Col span={5} offset={2}>
                        <Button type="default" block icon={<PlusCircleOutlined />} htmlType="submit">
                            {id && id !== '0' ? 'Update' : 'Create'}
                        </Button>
                    </Col>
                </Row>
            </Form>
        </Card>
    );
};

export default EditRestaurant;
