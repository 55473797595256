import axios from 'axios';
import { DefaultLocales } from '@tout-ma/general_models'

const API_KEY = 'AIzaSyBdkrikKQxluddYwwFknVbZjmU_ZeaudAU';
const API_URL = 'https://translation.googleapis.com/language/translate/v2';

export function translateText(text: string, source: DefaultLocales, target: DefaultLocales): Promise<string> {
    return new Promise(async (resolve, reject) => {
        try {

            const response = await axios.post(
                `${API_URL}?key=${API_KEY}`,
                {
                    q: text,
                    source,
                    target,
                }
            );

            resolve(decodeURI(response.data.data.translations[0]?.translatedText))
        } catch (error) {
            reject(error)
        }
    })
};

