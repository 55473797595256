import React, { useEffect, useState } from 'react';
import { t } from 'i18next';
import { Form, Button, Card, Col, Input, Divider, Row, Space, Table, TableColumnsType, Tag } from 'antd';
import {
    SearchOutlined,
    RedoOutlined,
    EditFilled,
} from '@ant-design/icons';
import { getListUsers } from '../../api/users';
import { User, UserCoreRole } from '@tout-ma/general_models';
export type userForm = {
    firstName?: string;
    lastName?: string;
    email?: string;
};

const AllUsersScreen = () => {
    const [form] = Form.useForm<userForm>();
    const [Users, setUsers] = useState<User[]>();
    const [Total, setTotal] = useState<number>(0);
    useEffect(() => {
        onFinish({  });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const onFinish = async (values: userForm) => {
        console.log('Success:', values);
        const users = await getListUsers(values);
        setUsers(users.list);
        setTotal(users.total);
    };
    const getTagColor = (role: UserCoreRole) => {
        switch (role) {
            case UserCoreRole.ADMIN:
            case UserCoreRole.SUPERADMIN:
                return 'red';
            case UserCoreRole.USER:
                return 'green';
            case UserCoreRole.RESTAURANTOWNER:
                return 'blue';
            default:
                return 'grey';
        }
    };
    const menuColumns: TableColumnsType<User> = [
        { title: 'First name', key: 'firstName', dataIndex: 'firstName' },
        { title: 'Last name', key: 'lastName', dataIndex: 'lastName' },
        { title: 'Email', key: 'email', dataIndex: 'email' },
        {
            title: 'Type',
            key: 'type',
            render: (user: User) => (
                <span>
                    {user.coreRole.map((role) => (
                        <Tag color={getTagColor(role)}>{role}</Tag>
                    ))}
                </span>
            ),
        },
        {
            title: '',
            key: 'operation',
            render: (user: User) => (
                <span>
                    <Button icon={<EditFilled />} size="small" >
                        
                    </Button>

                </span>
            )
        }
    ];
    return (
        <Card title={t('Menu Settings')} style={{ margin: 20, height: '100%' }}>
            <Card style={{ marginTop: '10px', marginBottom: '10px' }}>
                <Form layout="vertical" form={form} name="userLookup" onFinish={onFinish}>
                    <Row style={{ alignItems: 'center', marginTop: '10px' }}>
                        <Col span={10} offset={1}>
                            <Form.Item name={'firstName'} label="First name" rules={[{ required: false }]}>
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={10} offset={2}>
                            <Form.Item name={'email'} label="Email" rules={[{ required: false }]}>
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row style={{ alignItems: 'center', marginTop: '10px' }}>
                        <Col span={10} offset={1}>
                            <Form.Item name={'lastName'} label="Last name" rules={[{ required: false }]}>
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={10} offset={2}>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                                <Button htmlType="button" style={{ width: '45%' }} block icon={<RedoOutlined />} onClick={() => form.resetFields()}>
                                    Reset form
                                </Button>
                                <Button type="default" style={{ width: '45%' }} block icon={<SearchOutlined />} htmlType="submit">
                                    Look Up
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </Card>
            <Table columns={menuColumns} dataSource={Users} size="small" pagination={false} />
            <b>Total Results: {Total}</b>
        </Card>
    );
};

export default AllUsersScreen;
