import axios from 'axios';

const API_KEY = 'AIzaSyBdkrikKQxluddYwwFknVbZjmU_ZeaudAU';
const API_URL = 'https://maps.googleapis.com/maps/api/geocode/json';

export function addressToLatLng(address: string): Promise<{lat:number, lng:number}> {
    return new Promise(async (resolve, reject) => {
        try {
            let addressEncoded = encodeURI(address);

            const response = await axios.post(
                `${API_URL}?key=${API_KEY}&address=${addressEncoded}`
            );

            resolve(response.data.results?.[0]?.geometry?.location)
        } catch (error) {
            reject(error)
        }
    })
};
