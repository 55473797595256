// const isDev = process.env.NODE_ENV === 'development';
const isDev = false;

const env = {
    api_base_url: isDev ? 'http://localhost' : 'https://app.tout.ma',
    services: {
        users: isDev ? ':5000' : '/users',
        messaging: isDev ? ':5001' : '/messaging',
        restaurants: isDev ? ':5002' : '/restaurants',
        es: isDev ? ':5003' : '/es',
        immo: isDev ? ':5004' : '/immo',
        orders: isDev ? ':5005' : '/orders',
    },
    buckets: {
        restaurants: 'https://storage.googleapis.com/tout_restaurant_images/',
        immo: 'https://storage.googleapis.com/tout_immo_images/'
    },
    apikeys: {
        googlemaps: "AIzaSyCJA1XHGpcBh0iFx7DDVtgOrI4aZURlJUc",
        googletranslate: "AIzaSyBdkrikKQxluddYwwFknVbZjmU_ZeaudAU"
    },
    mapsettings: {
        INITIAL_REGION: {
            latitude: 35.20182,
            longitude: -3.90883,
            latitudeDelta: 0.2,
            longitudeDelta: 0.2,
        },
        TOP_RIGHT_BOUNDARY: { latitude: 36.0, longitude: -0.97291 },
        BOTTOM_LEFT_BOUNDARY: { latitude: 27.74179, longitude: -13.90955 }
    }
};

export default env;
