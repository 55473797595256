import { Routes, Route } from 'react-router-dom';
import AllUsersScreen from '../screens/users/AllUsersScreen';
import AllRestaurantsScreen from '../screens/restaurants/AllRestaurantsScreen';
import EditRestaurant from '../screens/restaurants/EditRestaurant';


const AppRoutes = () => {
    return (
        <Routes>
            <Route path="users" element={<AllUsersScreen/>} />
            <Route path="restaurants/edit/:id" element={<EditRestaurant/>} />
            <Route path="restaurants" element={<AllRestaurantsScreen/>} />
            <Route path="/" element={<p>Hello</p>}   />
        </Routes>
    );
};

export default AppRoutes;
