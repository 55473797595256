import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import i18n from './constants/i18n';
import { I18nextProvider } from 'react-i18next';
import { AuthContextProvider } from './contexts/AuthContext';
import { BrowserRouter } from 'react-router-dom';
import { APIProvider } from '@vis.gl/react-google-maps';
import { NotificationsContextProvider } from './contexts/NotificationsContext';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <I18nextProvider i18n={i18n}>
                <NotificationsContextProvider>
                    <AuthContextProvider>
                        <APIProvider apiKey={'AIzaSyCJA1XHGpcBh0iFx7DDVtgOrI4aZURlJUc'}>
                            <App />
                        </APIProvider>
                    </AuthContextProvider>
                </NotificationsContextProvider>
            </I18nextProvider>
        </BrowserRouter>
    </React.StrictMode>
);
