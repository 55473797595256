import { Restaurant } from "@tout-ma/general_models";
import { Api, PaginatedResult } from ".";
import { restaurantSearchForm } from "../screens/restaurants/AllRestaurantsScreen";
import { editRestaurantForm } from "../screens/restaurants/EditRestaurant";
import env from "../constants/env";

export function getListRestaurants(form: restaurantSearchForm): Promise<PaginatedResult<Restaurant>> {
    return new Promise(async (resolve, reject) => {
        try {
            const { data } = await Api({
                method: 'POST',
                url: `${env.api_base_url}${env.services.restaurants}/v1/admin/restaurants/search`,
                data: form
            });
            resolve(data);
        } catch (error: any) {
            reject(error);
        }
    });
}

export function getFullRestaurant(id: number): Promise<Restaurant> {
    return new Promise(async (resolve, reject) => {
        try {
            const { data } = await Api({
                method: 'GET',
                url: `${env.api_base_url}${env.services.restaurants}/v1/admin/restaurants/${id}`
            });
            resolve(data);
        } catch (error: any) {
            reject(error);
        }
    });
}

export function updateRestaurant(id: string, form: FormData): Promise<void> {
    return new Promise(async (resolve, reject) => {
        try {
            await Api({
                method: 'PUT',
                url: `${env.api_base_url}${env.services.restaurants}/v1/admin/restaurants/${id}`,
                data: form
            });
            resolve();
        } catch (error: any) {
            reject(error);
        }
    });
}

export function addNewRestaurant(form: FormData): Promise<void> {
    return new Promise(async (resolve, reject) => {
        try {
            await Api({
                method: 'POST',
                url: `${env.api_base_url}${env.services.restaurants}/v1/admin/restaurants/new`,
                data: form
            });
            resolve();
        } catch (error: any) {
            reject(error);
        }
    });
}

export function getRestaurantLoginToken(id: number): Promise<number> {
    return new Promise(async (resolve, reject) => {
        try {
            const { data } = await Api({
                method: 'GET',
                url: `${env.api_base_url}${env.services.restaurants}/v1/admin/restaurants/token/${id}`,
            });
            console.log(data)
            resolve(data.token);
        } catch (error: any) {
            reject(error);
        }
    });
}