import { User } from "@tout-ma/general_models";
import { Api, PaginatedResult } from ".";
import { userForm } from "../screens/users/AllUsersScreen";
import env from "../constants/env";

export function quickcheckAccess(bearerToken: string): Promise<void> {
    return new Promise(async (resolve, reject) => {
        try {
            const { data } = await Api({
                method: 'POST',
                url: `${env.api_base_url}${env.services.users}/v1/admin/quickcheck`,
                data: { bearerToken }
            });
            resolve(data);
        } catch (error: any) {
            console.error('quickcheckAccess', error);
            reject(error);
        }
    });


}
export function getListUsers(form:userForm): Promise<PaginatedResult<User>> {
    return new Promise(async (resolve, reject) => {
        try {
            const { data } = await Api({
                method: 'POST',
                url: `${env.api_base_url}${env.services.users}/v1/admin/users/search`,
                data: form
            });
            resolve(data);
        } catch (error: any) {
            console.error('quickcheckAccess', error);
            reject(error);
        }
    });
}