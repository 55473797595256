import React, { useContext } from 'react';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Card, Checkbox, Form, Input } from 'antd';
import { t } from 'i18next';
import { COLORS } from '../../constants/theme';

const LoginScreen= ({ signInWithEmail }: { signInWithEmail: (props: any) => void }) => {
    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '120px' }}>
            <Card title={t('Admin Dashboard - Login')} bordered={false} style={{ width: 600, border: '2px solid grey' }}>
                <Form name="loginForm" initialValues={{ remember: true }} onFinish={signInWithEmail}>
                    <Form.Item name="email" rules={[{ required: true, message: t('Please enter your email!'), type: 'email' }]}>
                        <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder={t('Username')} />
                    </Form.Item>
                    <Form.Item name="password" rules={[{ required: true, message: t('Please input your Password!') }]}>
                        <Input prefix={<LockOutlined className="site-form-item-icon" />} type="password" placeholder={t('Password')} />
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit" className="login-form-button">
                            {t('Log in')}
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        </div>
    );
};

export default LoginScreen;
